<template>
  <div class="search">
    <el-form :inline="true" ref="form" :model="search">
      <!-- <el-form-item>
        <el-input v-model="search.content" clearable>
          <template slot="prepend">内容</template>
        </el-input>
      </el-form-item>-->
      <el-form-item>
        <el-select v-model="search.status" placeholder="状态">
          <el-option
            v-for="item in statusOpts"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button class="feed-btn" @click="$emit('add')">提交反馈</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: { content: '', status: null },
      date: [],
      statusOpts: [
        { label: '请选择', value: '' },
        { label: '已解决', value: 1 },
        { label: '未解决', value: 0 }
      ]
    };
  },
  methods: {
    searchList() {
      let [startTime = '', endTime = ''] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit('search', param);
    }
  }
};
</script>

<style lang="scss" scoped>
.search {
  text-align: left;
  .feed-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
}
</style>