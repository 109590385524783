import API from "@/api";

export default {
  getList: params => {
    return API.requestPost({
      url: "feedback/getFeedbackData",
      data: params
    });
  },
  add: params => {
    return API.requestPost({
      url: "feedback/install",
      data: { sourceStatus: 1, ...params }
    });
  },
  // 反馈类型
  getType: () => {
    return API.requestPost(
      {
        url: "api/common/enum/selectFeedbackEnum"
      },
      false
    );
  }
};
