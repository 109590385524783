<template>
  <div class="feed">
    <el-table :data="list" style="width: 100%" border>
      <el-table-column prop="tempId" label="序号" align="center" width="60"></el-table-column>
      <el-table-column prop="type" label="类型" align="center"></el-table-column>
      <el-table-column prop="content" label="内容" align="center" min-width="160"></el-table-column>
      <el-table-column prop="createDate" label="日期" align="center"></el-table-column>
      <el-table-column label="状态" align="center" width="80">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status ? 'success' : 'danger' "
            size="mini"
            @click="$emit('del', scope.row)"
          >{{scope.row.status ? '已解决': '未解决'}}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
</style>